import React from "react";

const About = () => {
  return (
    <section id="about">
      <h1 className="about-title">More about Fund Raisin</h1>
      <iframe className="about-video" src="https://www.youtube-nocookie.com/embed/ZHj4OG2-Pzc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>    
      <br/>
      <br/>
      <a className="nav_tos" href="/tos">Please read our Terms of Service</a>
    </section>
  );
};

export default About;
