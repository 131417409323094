import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Swiper, SwiperSlide } from "swiper/react";
import Gdmorning from "../assets/images/Gdmorning.svg";
import Catrina from "../assets/images/Catrina.svg";
import { Autoplay } from "swiper";
import catdrop from "../assets/images/catdrop.png";
import bg2 from "../assets/images/bg2.png";
import earthquake from "../assets/images/earthquake.jpg";
import arrow_down from "../assets/images/arrow_down.svg";
import orange_arrow from "../assets/images/orange_arrow.svg";
import blue_arrow from "../assets/images/blue_arrow.svg";
import white_arrow from "../assets/images/white_arrow.svg";
import { NavLink } from "react-router-dom";
import white_arrow_down from "../assets/images/white_arrow_down.svg";

// Import Swiper styles
import "swiper/css";

const Home = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowText(true), 8000);
  }, []);

  return (
    <section id="home">
      <Swiper
        className="mySwiper"
        loop={true}
        speed={700}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <div id="hero1">
            <NavLink to="/about">
              <h1 className="hero-title">
                <div className={`beta-text-label`}>BETA</div>
                <span>Fund raising for everyone</span>
                <div className="text-wrap">
                  <span>with NFTs as rewards</span>
                  {/*<div className="arrow"></div>*/}
                  <img
                    className="arrow-right"
                    src={blue_arrow}
                    alt="arrow"
                  ></img>
                </div>
              </h1>
            </NavLink>
            {showText ? (
              <Link to="#fund" smooth>
                <div className="more-content-wrapper">
                  <img
                    className="arrow-down"
                    src={arrow_down}
                    alt="arrow"
                  ></img>
                  <span className="more-text">Scroll down for more</span>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div id="hero2">
            <a
              href={process.env.REACT_APP_MARKETPLACE_OCTOPI_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <h1 className="hero-title">
                <div className={`beta-text-label`}>BETA</div>
                <span>Let's save the oceans with</span>
                <div className="text-wrap">
                  <span>The Ocean Conservancy</span>
                  {/*<div className="arrow"></div>*/}
                  <img
                    className="arrow-right"
                    src={orange_arrow}
                    alt="arrow"
                  ></img>
                </div>
              </h1>
            </a>
            {showText ? (
              <Link to="#fund" smooth>
                <div className="more-content-wrapper more-content-wrapper-hidden">
                  <img
                    className="arrow-down"
                    src={arrow_down}
                    alt="arrow"
                  ></img>
                  <span className="more-text">Scroll down for more</span>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div id="hero3">
            <a
              href={process.env.REACT_APP_MARKETPLACE_CATDROPS_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <h1 className="hero-title">
                <div className={`beta-text-label`}>BETA</div>
                {/* <div class="tooltip"> */}
                <span>Let's save the animals with</span>
                <div className="text-wrap">
                  <span>FUNPROBIANI</span>
                  {/*<div className="arrow"></div>*/}
                  <img
                    className="arrow-right"
                    src={blue_arrow}
                    alt="arrow"
                  ></img>
                </div>
                {/* <span class="tooltiptext">Coming Soon</span>
              </div> */}
              </h1>
            </a>
            <img src={catdrop} alt="catdrop" className="banner-img" />
            {showText ? (
              <Link to="#fund" smooth>
                <div className="more-content-wrapper more-content-wrapper-hidden">
                  <img
                    className="arrow-down"
                    src={arrow_down}
                    alt="arrow"
                  ></img>
                  <span className="more-text">Scroll down for more</span>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div id="hero4">
            <a
              href={process.env.REACT_APP_MARKETPLACE_EARTHQUAKE_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <h1 className="hero-title">
                <div className={`beta-text-label`}>BETA</div>
                <span>Turkiye is struggling with the earthquake disaster</span>
                <div className="text-wrap">
                  <span>and we’re calling for your help!</span>
                  {/*<div className="arrow"></div>*/}
                  <img
                    className="arrow-right"
                    src={white_arrow}
                    alt="arrow"
                  ></img>
                </div>
              </h1>
            </a>
            {showText ? (
              <Link to="#fund" smooth>
                <div className="more-content-wrapper more-content-wrapper-hidden">
                  <img
                    className="arrow-down"
                    src={white_arrow_down}
                    alt="arrow"
                  ></img>
                  <span className="more-text white-text">Scroll down for more</span>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </SwiperSlide>
      </Swiper>
      <div id="fund">
        <h1 className="fund-title">Campaigns</h1>
        <div className="earthquake-collection">
          <div className="nft-collection">
            <img
              src={earthquake}
              alt="earthquake"
              className="nft-collection__img"
            ></img>

            <div className="nft-collection__info-wrapper">
              <div className="nft-collection__info-top">
                <h1 className="nft-collection__title earthquake-title">
                  Earthquake Relief Fund in Turkiye
                </h1>
                <p className="nft-collection__desc">
                  MUAFNFT, a curatorial contemporary art project on Avalanche,
                  is starting an aid campaign for the victims of the earthquake
                  in Turkey through the artist Can Köylüoğlu and Fund Raisin.
                </p>
              </div>
              <div className="nft-collection__info-bottom">
                <a
                  href={process.env.REACT_APP_MARKETPLACE_EARTHQUAKE_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="nft-collection__btn earthquake-btn">
                    Find out more
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="nft-collection">
          <img
            src={Gdmorning}
            alt="octopi"
            className="nft-collection__img"
          ></img>

          <div className="nft-collection__info-wrapper">
            <div className="nft-collection__info-top">
              <h1 className="nft-collection__title octopi-title">
                The Octopi Collection
              </h1>
              <p className="nft-collection__desc">
                Discover, collect, and trade extraordinary octopus NFT art while
                saving the oceans with red·dev's fundraiser for{" "}
                <strong>The Ocean Conservancy</strong>.
              </p>
            </div>
            <div className="nft-collection__info-bottom">
              <a
                href={process.env.REACT_APP_MARKETPLACE_OCTOPI_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <div className="nft-collection__btn octopi-btn">
                  Seems Rare?
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="catdrop-collection">
          <div className="nft-collection">
            <img
              src={Catrina}
              alt="catdrop"
              className="nft-collection__img catdrop-img"
            ></img>

            <div className="nft-collection__info-wrapper">
              <div className="nft-collection__info-top">
                <h1 className="nft-collection__title catdrop-title">
                  The Catdrops Collection
                </h1>
                <p className="nft-collection__desc">
                  Discover, collect, and trade extraordinary catdrop NFTs while
                  protecting animals with <strong>FUNPROBIANI</strong>.
                </p>
              </div>
              <div className="nft-collection__info-bottom">
                <a
                  href={process.env.REACT_APP_MARKETPLACE_CATDROPS_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="nft-collection__btn catdrop-btn">
                    Seems Rare?
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
