import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
//import Menu from "../Modal/Menu";
import Modal from "../Modal/Modal";
import Popup from "../Modal/Popup";
import ArtistMenu from "../Modal/ArtistMenu";
import FundMenu from "../Modal/FundMenu";

//mobile
import ham from "../../assets/images/ham_burger.svg";
//import { logDOM } from "@testing-library/react";

const Header = () => {
  const [showArtistModal, setShowArtistModal] = useState(false);
  const [showFundModal, setShowFundModal] = useState(false);
  const toggleArtistModal = () => {
    setShowArtistModal(!showArtistModal);
  };
  const toggleFundModal = () => {
    setShowFundModal(!showFundModal);
  };

  const renderArtistModal = () => {
    return (
      <Modal show={showArtistModal}>
        <Popup>
          <ArtistMenu onClose={toggleArtistModal} />
        </Popup>
      </Modal>
    );
  };

  const renderFundModal = () => {
    return (
      <Modal show={showFundModal}>
        <Popup>
          <FundMenu onClose={toggleFundModal} />
        </Popup>
      </Modal>
    );
  };
  const openMenu = () => {
    document.getElementById("sidenav").style.width = "70%";
  };

  const closeMenu = () => {
    document.getElementById("sidenav").style.width = "0px";
  };

  return (
    <header>
      <nav className="navbar">
        {/*MOBILE*/}
        <div className="nav__mobile-box">
          <a
            href={'/'}
            rel="noreferrer"
          >
            <img className="nav__logo" src={logo} alt="logo" />
          </a>
          <img src={ham} className="ham_menu" alt="" onClick={openMenu} />
        </div>
        <div className="nav__menu-box">
          <ul className="nav__menu-items">
            <NavLink to="/about">
              <li className="nav__menu-item">About</li>
            </NavLink>
            {/* <div class="tooltip"> */}
            <li className="nav__menu-item" onClick={toggleArtistModal}>
              I am an Artist
            </li>
            {/* <span class="tooltiptext">Coming Soon</span>
            </div> */}
            {/* <div class="tooltip"> */}
            <li className="nav__menu-item" onClick={toggleFundModal}>
              My Fundraisers
            </li>
            {/* <span class="tooltiptext">Coming Soon</span>
            </div> */}
          </ul>
        </div>
        <div id="sidenav" className="nav__menu-box">
          <button className="closebtn" onClick={closeMenu}>
            &times;
          </button>
          <ul className="nav__menu-items">
            <Link
              to="/#home"
              smooth
              offset={500}
              duration={500}
              className="nav__menu-item-box"
            >
              <li className="nav__menu-item"> Home </li>
            </Link>
            <Link
              to="/about"
              smooth
              offset={500}
              duration={500}
              className="nav__menu-item-box"
            >
              <li className="nav__menu-item"> About </li>
            </Link>
            <Link
              to="/about"
              smooth
              offset={500}
              duration={500}
              className="nav__menu-item-box"
            >
              <li className="nav__menu-item" onClick={toggleArtistModal}>
                {" "}
                I am an Artist{" "}
              </li>
            </Link>
            <Link
              to="/about"
              smooth
              offset={500}
              duration={500}
              className="nav__menu-item-box"
            >
              <li className="nav__menu-item" onClick={toggleFundModal}>
                My Fundraisers
              </li>
            </Link>
          </ul>
        </div>
      </nav>
      {renderArtistModal()}
      {renderFundModal()}
    </header>
  );
};

export default Header;
