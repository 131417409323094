import './index.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Template/Header";
import Footer from "./components/Template/Footer";
import Home from './components/Home';
import About from './components/About';
import TermsOfService from './components/TermsOfService';
import { ethers } from "ethers";
import Modal from './components/Modal/Modal';
import Popup from './components/Modal/Popup';
import Error from './components/Modal/Error';
import { browserName, browserVersion } from "react-device-detect";

function App() {
  const [address, setAddress] = useState(0);
  const [errShow, setErrShow] = useState(false);
  const [errMessage, setErrorMessage] = useState();

  const connectWallet = async () => {
    let errorMsg = ''
    if(window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      if (window.ethereum.chainId !== "0xa869") {
        errorMsg = `Failed: Network not supported. Check whether connected to Fuji Network`
        openErrorModal(errorMsg)
        alert(errorMsg)
        return;
      } else {
        const walletAddress = await provider.getSigner().getAddress();
        setAddress(walletAddress);
        return true;
      }
    }
    else {
      errorMsg = "Non-Ethereum browser detected. Please install MetaMask!"
      openErrorModal(errorMsg)
    }
  };

  const checkBrowser = () => {
    let errorMsg = ''
    if(browserName !== "Chrome") {
      errorMsg = "Fund Raisin sites are currently only supported on Chrome and Brave desktop browsers."
      openErrorModal(errorMsg)
      return
    }
  }
  useEffect(() => {
    // console.log(`${browserName} ${browserVersion}`)
    checkBrowser()
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
      window.ethereum.on("accountsChanged", () => {
        connectWallet();
      });
    }
  }, []);

  const openErrorModal = (err) => {
    setErrorMessage(err);
    setErrShow(!errShow);
  }

  const closeErrorModal = () => {
    setErrShow(!errShow);
  }

  const renderErrorPopup = () => {
    return (
      <Modal show={errShow}>
        <Popup>
          <Error err={errMessage} onClose={closeErrorModal} />
        </Popup>
      </Modal>
    )
  }

  return (
    <div className="App">
      {renderErrorPopup()}
      <Router>
          <Header
          address={address}
          connectWallet={connectWallet}
          />
          <div className="template-body">
            <Routes>
              <Route
                exact
                path="/"
                element={<Home />}
              />
              <Route path="/about" element={<About />} />
              <Route path="/tos" element={<TermsOfService />} />
              
            </Routes>
          </div>
          <Footer />
        </Router>
    </div>
  );
}

export default App;
