import React from 'react';

const Modal = ({children, show}) => {
  const modalClass = show ? 'modal d-block' : 'modal d-none'
  return (
    <div className={modalClass}>
      {children}  
    </div>
  )
};

export default Modal;