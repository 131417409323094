import React from 'react';

const Popup = ({ children, show }) => {
    return (
        <div className='popup'>
            {children}
        </div>
    )
};

export default Popup;
