import React from 'react'

const ArtistMenu = ({onClose}) => {
  return (
    <div className='menu-wrapper'>
        <h3 className='menu-text'>If you would like to create art for Fund Raisin fundraisers (and receive payments every time your art is used), please get in touch with us on <a href='https://discord.gg/R9g6Jjy7TW' target='_blank' className='menu-link'>Discord</a> or via a Twitter DM to us at <a href='https://twitter.com/FundRaisinApp' target='_blank' className='menu-link'>@fundraisinapp</a>. We will help you get it up and running! Soon we will be releasing tools to help you even more.</h3>
        <button className='menu-btn' onClick={onClose}>OK</button>
    </div>
  )
}

export default ArtistMenu