import React, {useEffect, useState} from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import tos from "./md_files/tos.md"

const TermsOfService = () => {
  const [tosText, setTosText] = useState('')

  useEffect(() => {
		fetch(tos).then(res => res.text()).then(text => setTosText(text))
	});
  
  return (
    <>
    <section id="about">
      <h1 className="about-title">Fund Raisin Terms of Service</h1>

      <div className="tos-container">

        <ReactMarkdown children={tosText} remarkPlugins={[remarkGfm]}/>
        <ReactMarkdown>

        </ReactMarkdown>
      </div>
    </section>

    </>
  );
};

export default TermsOfService;
